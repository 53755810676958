import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "container", "values", "valuesContainer"];

  connect() {
    this.toggleAllValuesFields();
  }

  addField(event) {
    event.preventDefault();
    const timestamp = new Date().getTime();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, timestamp);
    const newElement = document.createElement("div");
    newElement.innerHTML = content;
    this.containerTarget.appendChild(newElement);
    this.initializeActions(newElement);
    this.toggleAllValuesFields();
  }

  initializeActions(element) {
    const elements = element.querySelectorAll("[data-action]");
    elements.forEach((el) => {
      const action = el.dataset.action;
      const [event, controllerAction] = action.split("->");
      const [controller, actionMethod] = controllerAction.split("#");
      if (controller === this.identifier) {
        el.addEventListener(event, this[actionMethod].bind(this));
      }
    });
  }

  addValue(event) {
    event.preventDefault();
    const container = event.target
      .closest(".nested-fields")
      .querySelector('[data-nested-form-target="valuesContainer"]');
    const valuesInput = event.target
      .closest(".nested-fields")
      .querySelector('[data-nested-form-target="values"]');
    const currentValues = JSON.parse(valuesInput.value || "[]");
    const newValueInput = document.createElement("input");
    newValueInput.type = "text";
    newValueInput.name = "new_value";
    newValueInput.placeholder = "Enter a value";
    newValueInput.addEventListener("blur", () => {
      const newValue = newValueInput.value.trim();
      if (newValue) {
        currentValues.push(newValue);
        valuesInput.value = JSON.stringify(currentValues);
        const valueElement = document.createElement("div");
        valueElement.innerHTML = `
          <span>${newValue}</span>
          <button data-action="click->nested-form#removeValue" data-value="${newValue}">Remove</button>
        `;
        container.appendChild(valueElement);
        this.initializeActions(valueElement); // Initialize actions for new elements
        newValueInput.remove();
      }
    });
    container.appendChild(newValueInput);
    newValueInput.focus();
  }

  removeValue(event) {
    event.preventDefault();
    const valueToRemove = event.target.dataset.value;
    const container = event.target
      .closest(".nested-fields")
      .querySelector('[data-nested-form-target="valuesContainer"]');
    const valuesInput = event.target
      .closest(".nested-fields")
      .querySelector('[data-nested-form-target="values"]');
    let currentValues = JSON.parse(valuesInput.value || "[]");
    currentValues = currentValues.filter((value) => value !== valueToRemove);
    valuesInput.value = JSON.stringify(currentValues);
    container.innerHTML = "";
    currentValues.forEach((value) => {
      const valueElement = document.createElement("div");
      valueElement.innerHTML = `
        <span>${value}</span>
        <button data-action="click->nested-form#removeValue" data-value="${value}">Remove</button>
      `;
      container.appendChild(valueElement);
      this.initializeActions(valueElement); // Initialize actions for new elements
    });
  }

  removeField(event) {
    event.preventDefault();
    let field = event.target.closest(".nested-fields");
    // Check if the field is a new record
    if (field.dataset.newRecord === "true") {
      // If it is a new record, remove it from the DOM
      field.remove();
    } else {
      // If it's an existing record, hide it and set the _destroy flag
      field.querySelector("input[name*='_destroy']").value = 1;
      field.style.display = "none";
    }
  }

  toggleValuesField(event) {
    const fieldType = event.target.value;
    const valuesField = event.target
      .closest(".nested-fields")
      .querySelector(".values-field");
    if (fieldType === "dropdown" || fieldType === "radio") {
      valuesField.style.display = "block";
    } else {
      valuesField.style.display = "none";
    }
  }

  toggleAllValuesFields() {
    this.containerTarget.querySelectorAll(".nested-fields").forEach((field) => {
      const fieldType = field.querySelector("select[name*='[field_type]']").value;
      const valuesField = field.querySelector(".values-field");
      if (fieldType === "dropdown" || fieldType === "radio") {
        valuesField.style.display = "block";
      } else {
        valuesField.style.display = "none";
      }
    });
  }
}